export const ADDRESS_RIGHT = {
  BASE_URL: "https://www.addressright.co.nz",
  API_KEY: "333093_Q5wdTu6YcsWhyRTu",
};

export const MEAL_PRICE = 13.33;

export const ENCRYPT_KEY = "timata2023";

export const TIWLLIO_API_KEY = "AC5f12666e5fd6d8309f6a347033ba546d";

export const TIWILLO_AUTH_TOKEN = "83160a8fdf8de761d23f9ad7fd00962a";

export const POSTMARKAPP_ACCOUNT_KEY = "f423224f-0543-4b09-8965-0eba3f40adb0";

export const INTERCOM_ID = "i5f7hzxc";

export const ENCRYPT_ADMIN_KEY = "timata2023";

const IS_STAGING = true;

export const IS_DEVELOPMENT = process.env.NODE_ENV === "development";

export const DELIVERY_KEY =
  IS_DEVELOPMENT || IS_STAGING
    ? "https://customer-integration.ep-sandbox.freightways.co.nz"
    : "https://customer-integration.ep-prod.freightways.co.nz";

export const API_URL = IS_DEVELOPMENT
  ? "http://127.0.0.1:5000"
  : IS_STAGING
  ? "https://s1.timata.nz"
  : "https://app.timata.nz";

export const STRIPE_PUBLIC_KEY =
  IS_DEVELOPMENT || IS_STAGING
    ? "pk_test_51ONAcsG49PXYauDgZDXC9J48P3cEb3rclqoBr6iV2Hm7uQXP8984JT6Zg0wt2OFKv33YXLXEp77m8Hg2BLji0nPq00TMO3NSj8"
    : "pk_live_51N1IjvI9txU7ivFe6eaeUapKH6BhfPHe9MlE3OPbe8pOz58u61GeqjQ5GDbBYW8bfNIX2FXhESXzovNknea5eyGq00zPQxuwqf";
